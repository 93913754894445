import { isLogined } from '@/utils/auth';
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// 定义路由
const router = new Router({
    routes: [

        {
            path: '/',
            component: () => import('../pages/homePage.vue'),
            meta: { title: '世界再大，也要回家！' },
        },
        {
            path: '/peoplepublish',
            component: () => import('../pages/peoplePublishRecord.vue'),
            meta: { title: '老乡发布的信息', auth: true },
        },
        {
            path: '/findcar',
            component: () => import('../pages/peopleFindCarPage.vue'),
            meta: { title: '人找车', auth: false, keepAlive: true },
        },
        {
            path: '/findcarresult',
            component: () => import('../pages/findCarResult.vue'),
            meta: { title: '找车结果' },
        },
        {
            path: '/driverpublish',
            component: () => import('../pages/driverPublishRecord.vue'),
            meta: { title: '车主发布的信息', auth: true },
        },
        {
            path: '/findpeople',
            component: () => import('../pages/driverFindPeoplePage.vue'),
            meta: { title: '车找人', auth: false, keepAlive: true },
        },
        {
            path: '/findpeopleresult',
            component: () => import('../pages/findPeopleResult.vue'),
            meta: { title: '找人结果' },
        },

        {
            path: '/viewinforecord',
            component: () => import('../pages/viewInfoRecord.vue'),
            meta: { title: '信息查看记录', auth: true },
        },
        {
            path: '/login',
            component: () => import('../pages/login.vue'),
            meta: { title: '登录', keepAlive: true },
        },
        {
            path: '/payorder',
            component: () => import('../pages/payPage.vue'),
            meta: { title: '支付', auth: true, keepAlive: true },
        },
        {
            path: '/myinfo',
            component: () => import('../pages/myInfo.vue'),
            meta: { title: '我的信息', auth: true },
        },
        {
            path: '/protocol',
            component: () => import('../pages/protocol.vue'),
            meta: { title: '协议' },
        },
        {
            path: '/joinmember',
            component: () => import('../pages/joinMember.vue'),
            meta: { title: '加入会员', auth: true },
        },
        {
            path: '/usetest',
            component: () => import('../pages/useTest.vue'),
            meta: { title: '测试用户' },
        },
        {
            path: '/404',
            component: () => import('../pages/404.vue'),
            meta: { title: '找不到' },
        },
        {
            path: '*',
            redirect: '/404',
        },
    ],
});

/**
 *  to要跳转到的路径
    from从哪个路径来
    next往下执行的回调
 */
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    let token = isLogined();
    console.log('from:', from.path, '-->', 'to:', to.path);
    console.log('token:', token);
    // 判断该页面是否需要登录
    if (to.meta.auth) {
        if (token) {
            next();
        } else {
            // 否则跳转到login登录页面
            next({
                path: '/login',
                // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
                query: {
                    redirect: to.fullPath,
                },
            });
        }
    } else {
        // 如果不需要登录，则直接跳转到对应页面
        next();
    }
});

router.onError((callback) => {
    // 2.4.0新增 并不常用，了解一下就可以了
    console.log(callback, 'callback');
});

export default router;
