<template>
    <div id="app" class="d-flex justify-content-center">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
    </div>
</template>

<script>



export default {
    name: 'App'
}
</script>

<style>
.max-screen {
    width: 100%;
    height: 100%;
    position: absolute;
}

/**应用于弹框的样式 */
.border-head {
    border: none !important;
}

.mybody {
    padding: 0 !important;
}

.mcontent {
    border-radius: 1rem !important;
}
</style>
